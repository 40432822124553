import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
// import { sendEmail } from '../utils/sendEmail';

export default function LifestyleCounseling() {
  const price = 25000;
  const customerUrl = '/customers';
  const [customer, setCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [visitor, setVisitor] = useState({
    fName: '',
    lName: '',
    email: '',
  });

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('price', price);
    localStorage.setItem('customerId', customerId);
    localStorage.setItem('visitor_email', visitor.email);
    localStorage.setItem('visitor_fName', visitor.fName);
    localStorage.setItem('visitor_lName', visitor.lName);

    setCustomer({
      address: {
        city: '',
        country: '',
        line1: '',
        line2: '',
        postal_code: '',
        state: '',
      },
      email: '',
      fName: '',
      lName: '',
    });

    setCustomerId('0');
  }, [customerId, visitor.email, visitor.fName, visitor.lName]);

  const handleClick = async (e) => {
    e.preventDefault();

    await axios
      .post(customerUrl, customer)
      .then((res) => {
        setCustomerId(res.data.id);
      })
      .catch((err) => {
        console.log('Error, could not make new customer', err);
      });

    localStorage.setItem('customerId', customerId);
    localStorage.setItem('visitor_email', visitor.email);
    localStorage.setItem('visitor_fName', visitor.fName);
    localStorage.setItem('visitor_lName', visitor.lName);
    localStorage.setItem('service', 'Lifestyle Counseling');

    history.push('/checkout');
  };

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="service">
      <div className="wrapper">
        <h1>Lifestyle Counseling</h1>
        <div className="content">
          <div className="description">
            <p>
              Lifestyle counseling is meant to help you meet your
              goals and needs in a way that works for you and meet you
              where you are right now. The initial assessment will
              include an introduction about your and your history with
              diet, food, and even exercise. This will also include a
              conversation about what your goals are and how I can
              help you reach them. <br /> <br />
              This can also include meal plans and recipe guides, if
              requested. We will have monthly, 30-minute meetings to
              review progress and set new or update actionable goals.
            </p>
            <br /> <br />
            <p>Price: $250.00</p>
            <p>Duration: 6 Month Minimum Contract</p>
          </div>
          <div className="form">
            <h3>Sign Up Now</h3>
            <form>
              <input
                name="fName"
                onChange={handleChange}
                value={visitor.fName}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lName"
                onChange={handleChange}
                value={visitor.lName}
                type="text"
                placeholder="Last Name"
              />
              <input
                name="email"
                onChange={handleChange}
                value={visitor.email}
                type="email"
                placeholder="Email"
              />
              <button onClick={handleClick}>Sign Up</button>
            </form>
          </div>
        </div>
        {/* <div className="email-form">
          <h2>Questions? Send them here!</h2>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              id="firstName"
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              id="firstName"
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              id="email"
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div> */}
      </div>
    </div>
  );
}
