import emailjs from "@emailjs/browser";
import { consultSuccess, consultFail } from "../alerts/consultAlert";

export const sendEmail = (
  fName,
  lName,
  email,
  phone,
  message,
  street,
  unit,
  city,
  state,
  zip,
  insurance,
  service,
  reference
) => {
  emailjs
    .send("default_service", "template_xn5fxrk", {
      to_name: 'Jake',
      fName,
      lName,
      email,
      phone,
      message,
      street,
      unit,
      city,
      state,
      zip,
      insurance,
      service,
      reference,
    }, "user_uscJE2OKi8jiqjr9ITiY4")
    .then((res) => {
      if(res.status !== 200) {
        consultFail()
      } else {
        consultSuccess()
      }
    })
    .catch((err) => {
      console.log("NEWS FAILED HERE", err);
    });
};
