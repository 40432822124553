import React, { useState } from "react";
import headshot from "../images/headshot.jpg";
import { sendEmail } from "../utils/emails/aboutMessage";

export default function About() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="about">
      <div className="wrapper">
        <div className="upper-third">
          <div className="profile">
            <div className="cropped">
              <img src={headshot} alt="headshot" />
            </div>
            <div className="text">
              <h1>Jake Mey, PhD, RD</h1>
              <h3>Postdoctoral Research Fellow</h3>
              <h4>Integrated Phyisology and Molecular Medicine Laboratory</h4>
              <h5>Pennington Biomedical Research Center</h5>
            </div>
          </div>
          <div className="about-contact">
            <h2>Contact</h2>
            <div className="form">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendEmail(
                    visitor.fName,
                    visitor.lName,
                    visitor.email,
                    visitor.message
                  );
                }}
              >
                <div className="left-half">
                  <input
                    required
                    type="text"
                    placeholder="First Name"
                    name="fName"
                    value={visitor.fName}
                    onChange={handleChange}
                  />
                  <input
                    required
                    type="text"
                    placeholder="Last Name"
                    name="lName"
                    value={visitor.lName}
                    onChange={handleChange}
                  />
                  <input
                    required
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={visitor.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="right-half">
                  <textarea
                    required
                    name="message"
                    value={visitor.message}
                    onChange={handleChange}
                  />
                  <button className="profile-btn" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <hr />
        <div className="middle-third">
          <h2>Profile</h2>
          <p>
            Dr. Jacob Mey is a leading expert in human nutrition and metabolism.
            He obtained his PhD from the University of Illinois, at Chicago in
            Kinesiology, Nutrition and Rehabilitation Sciences. He currently
            works in a research lab that investigates the impact of nutrition on
            whole-body and skeletal muscle metabolism. Dr. Mey uses
            clinical-translational research approaches to understand the links
            between nutrition and health.{" "}
            <a href="https://pubmed.ncbi.nlm.nih.gov/?term=Mey%20JT%5BAuthor%5D&sort=date">
              His research studies
            </a>{" "}
            utilize human diet and exercise interventions along with cell
            culture mechanistic experiments. His interests include whole-body
            and skeletal muscle insulin sensitivity and protein turnover.
          </p>
          <h3>Lifestyle Counseling</h3>
          <p>
            Dr. Mey is also a registered and licensed dietitian. He has
            experience successfully implementing short-term intensive
            interventions (for body composition or sports performance goals)
            along with long-term lifestyle interventions (for achieving health
            and wellness while enjoying a permanent dietary shift). Dr. Mey has
            performed these lifestyle counseling strategies in both clinical
            research and private practice settings. He takes a client-centered
            approach, encouraging clients to try various dietary patterns
            according to their preference and including: Mediterranean diet,
            low-carb/ketogenic diet, vegetarian diets among others.
          </p>
          <h3>Supplements and Botanicals</h3>
          <p>
            For several years during his undergrad years, Dr. Mey also managed
            national-chain nutrition and supplement retail stores (company
            undisclosed). Here, he gained a strong appreciation for the effects
            of vitamin, mineral, herbal and botanical supplements for a variety
            of ailments that his customers reported. He took this interest into
            the research realm where he received funding to research the impact
            of botanical supplements on human whole-body protein turnover. He
            uses this combination of knowledge, the self-reported benefits of
            supplements along with the clinical trial research data, to
            recommend the best supplement regimens for his clients.
          </p>
          <h3>Personal Weight and Diet History...and Eating Competitions</h3>
          <p>
            Dr. Mey has a long history of weight cycling, both intentionally and
            unintentionally. He has a strong appreciation for the feelings and
            struggles that come with focusing on health and nutrition. As a
            youth, he personally struggled with bodyweight until his teen years,
            when he started eating a ketogenic diet. He lost ~30 lbs as a young
            teen and began to excel at sports (maintaining weight loss after
            stopping the ketogenic diet). He was enthralled by the profound
            impact that food had on his body and thus began his love for
            nutrition science. As he moved towards his undergrad education, he
            played NCAA football as a defensive nose tackle at Case Western
            Reserve University, starting all 3 years he played. During these
            years, he consistently weight cycled ~40 lbs between in-season
            football weight versus off-season beach body weight.
            <br />
            <br />
            After he finished athletics and began his doctoral studies, Dr. Mey
            struggled with transitioning his high-calorie athlete diet towards a
            lower-calorie diet to accommodate the long desk-hours and sedentary
            work environment. During these years, he slowly, but consistently
            gained weight, nearing his largest weight just under 300lbs. From
            this turning point, Dr. Mey used the next several years to return to
            a lower-calorie lifestyle and achieving a health weight ~75 lbs less
            than his heaviest. During this time he employed various eating
            styles, as he enjoys experimenting with classic and popular diets.
            He even experimented with a 10-day fast, although he did this purely
            for the experience, not for weight loss, as it is not evidenced and
            was even advertised in the early 1900s – to help GAIN weight. He
            also believes trying all the common eating patterns is a necessary
            component to effectively counsel others – a first-hand understanding
            the intricacies of each dietary pattern. He did this without
            removing desserts, favorite foods, holiday parties, trips to the bar
            and eating competitions. His greatest eating competition achievement
            was{" "}
            <a href="https://meltbarandgrilled.com/the-melt-experience/the-melt-challenge/">
              The Melt Challenge
            </a>
            , a meal estimated at ~8000 calories. To date, he remains the only
            PhD, RD that has successfully completed this challenge.
          </p>
        </div>
        <hr />
        {/* <div className="bottom-third">
          <a href="/curriculum_vitae">
            <h2>Curriculum Vitae</h2>
          </a>
        </div> */}
      </div>
    </div>
  );
}
