import Swal from 'sweetalert2';

export const consultSuccess = async () => {
  await Swal.fire({
    title: 'Form Submitted!',
    timer: 5000,
    text: "Thank you for your interest in working with Evidenced Nutrition! We will review your questionnarie and respond to you as soon as possible!",
    icon: 'success',
  }).then(() => {
    window.location.reload();
  });
};

export const consultFail = async () => {
    await Swal.fire({
      title: 'Error: Could not submit form',
      text: "Sorry, but we could not process your request. Please try again later.",
      timer: 5000,
      icon: 'error',
    }).then(() => {
      window.location.reload();
    });
  };