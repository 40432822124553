import React from 'react'

export default function Curriculum() {
    return (
        <div className='curriculum'>
            <div className='wrapper'>
                This is the curriculum vitae page
            </div>
        </div>
    )
}
