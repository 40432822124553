import React, { useState } from "react";
import { useCookies } from "react-cookie";
import bcrypt from "bcryptjs";
import axios from "axios";
import { sendEmail } from "../utils/emails/ConsultForm";

export default function RequestButton() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    message: "",
    street: "",
    unit: "",
    city: "",
    state: "",
    zip: "",
    insurance: "",
    service: "",
    reference: "",
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  const [cookies, setCookies] = useCookies(["cons_req"]);

  const handleClick = () => {

    axios
      .get(process.env.REACT_APP_CONSULT)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        setCookies(
          "cons_req",
          bcrypt.hashSync(Math.random().toString(36).substr(2, 9)),
          {
            name: "consult request",
            path: "/request_consult",
            maxAge: 30 * 24 * 60 * 60 * 100,
            domain: "",
            secure: true,
            sameSite: "none",
          }
        );
        window.location.reload();
        localStorage.setItem("ev_nutri_", bcrypt.hashSync(toString(cookies)));
        localStorage.removeItem("ev_nutri_");
      })
      .catch((err) => console.log("fail: ", err));
  };

  return (
    <div className="consult">
      <div className="wrapper">
        <div className="container">
          <div className="consult-form">
            <form
              className="consult-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleClick();
                sendEmail(
                  visitor.fName,
                  visitor.lName,
                  visitor.email,
                  visitor.phone,
                  visitor.message,
                  visitor.street,
                  visitor.unit,
                  visitor.city,
                  visitor.state,
                  visitor.zip,
                  visitor.insurance,
                  visitor.service,
                  visitor.reference
                );
              }}
            >
              <h1>Request a Consultation</h1>
              <p>
                Interested in learning more about what plan or service might fit
                your needs? Contact us today and let's set up a time to chat!
              </p>
              <div className="intake-area">
                <h2>Name</h2>
                <div className="input-area">
                  <input
                    type="text"
                    name="fName"
                    placeholder="First Name"
                    required
                    onChange={handleChange}
                    value={visitor.fName}
                  />
                  <input
                    type="text"
                    name="lName"
                    placeholder="Last Name"
                    required
                    onChange={handleChange}
                    value={visitor.lName}
                  />
                </div>
                <h2>Address</h2>
                <input
                  placeholder="Street Address"
                  className="extended"
                  type="text"
                  id="street1"
                  name="street"
                  required
                  onChange={handleChange}
                  value={visitor.street}
                />
                <input
                  className="extended"
                  placeholder="Apartment or Unit Number"
                  type="text"
                  id="street2"
                  name="unit"
                  onChange={handleChange}
                  value={visitor.unit}
                />
                <div className="input-area">
                  <input
                    placeholder="City"
                    type="text"
                    id="city"
                    name="city"
                    required
                    onChange={handleChange}
                    value={visitor.city}
                  />
                  <input
                    placeholder="State"
                    type="text"
                    id="state"
                    name="state"
                    required
                    onChange={handleChange}
                    value={visitor.state}
                  />
                </div>
                <input
                  className="extended"
                  placeholder="Zip Code"
                  type="text"
                  id="zip"
                  name="zip"
                  required
                  onChange={handleChange}
                  value={visitor.zip}
                />
                <h2>Contact</h2>
                <div className="input-area">
                  <input
                    placeholder="Email"
                    type="text"
                    id="email"
                    name="email"
                    required
                    onChange={handleChange}
                    value={visitor.email}
                  />
                  <input
                    placeholder="Phone"
                    type="text"
                    id="phone"
                    name="phone"
                    required
                    onChange={handleChange}
                    value={visitor.phone}
                  />
                </div>
                <h2>Additional</h2>
                <textarea
                  className="extended longform"
                  placeholder="Message; Minimum 140 characters, Max 1000 characters"
                  type="textarea"
                  id="message"
                  name="message"
                  cols='40'
                  rows='20'
                  required minLength={140} maxLength={1000} 
                  onChange={handleChange}
                  value={visitor.message}
                />
                <input
                  className="extended"
                  placeholder="Insurance"
                  type="text"
                  id="insurance"
                  name="insurance"
                  onChange={handleChange}
                  value={visitor.insurance}
                />
                <input
                  className="extended"
                  placeholder="Service You're Interested In"
                  type="text"
                  id="service"
                  name="service"
                  onChange={handleChange}
                  value={visitor.service}
                />
                <input
                  className="extended"
                  placeholder="Reference"
                  type="text"
                  id="reference"
                  name="reference"
                  onChange={handleChange}
                  value={visitor.reference}
                />
              </div>
              <button type="submit" className="consult-button">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
