import emailjs from '@emailjs/browser';

export const sendEmail = (fName, lName, email, message) => {
  emailjs
    .send(
      'default_service',
      'template_2gqluco',
      {
        to_name: 'Jake',
        fName,
        lName,
        email,
        message,
      },
      'user_uscJE2OKi8jiqjr9ITiY4'
    )
    .then((res) => {
      window.location.reload();
    })
    .catch((err) => {
      console.log('NEWS FAILED HERE', err);
    });
};
