import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
// import { sendEmail } from "../utils/sendEmail";

export default function MetabolicConsultation() {
  const price = 10000;
  const customerUrl = `${process.env.REACT_APP_BASE_URL}/customers`;
  const [customer, setCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("price", price);
    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);
    localStorage.setItem("service", "Metabolic Counseling");

    setCustomer({
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      email: "",
      fName: "",
      lName: "",
    });

    setCustomerId("0");
  }, [customerId, visitor.email, visitor.fName, visitor.lName]);

  const handleClick = async (e) => {
    e.preventDefault();

    await axios
      .post(customerUrl, customer)
      .then((res) => {
        setCustomerId(res.data.id);
      })
      .catch((err) => {
        console.log("Error, could not make new customer", err);
      });

    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);

    history.push("/checkout");
  };

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="service">
      <div className="wrapper">
        <h1>Metabolic Consultation</h1>
        <div className="content">
          <div className="description">
            <p>
              30 minute meeting (virtual), open dialog conversation regarding
              your questions and personal goals. Receive expert recommendation
              on approaches to address your needs. (Full $100 credit applied if
              you decide to enroll in Evidenced Nutrition counseling services).
            </p>
            <br />
            <br />
            <p>Price: $100.00</p>
          </div>
          <div className="form">
            <h3>Sign Up Now</h3>
            <form action="">
              <input
                name="fName"
                onChange={handleChange}
                value={visitor.fName}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lName"
                onChange={handleChange}
                value={visitor.lName}
                type="text"
                placeholder="Last Name"
              />
              <input
                name="email"
                onChange={handleChange}
                value={visitor.email}
                type="email"
                placeholder="Email"
              />
              <button onClick={handleClick}>Sign Up</button>
            </form>
          </div>
        </div>
        {/* <div className="email-form">
          <h2>Questions? Send them here!</h2>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              required
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div> */}
      </div>
    </div>
  );
}
