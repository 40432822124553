import React from "react";
import { useHistory } from "react-router-dom";

export default function Services() {

    let history = useHistory();

    return (
    <div className="services">
      <div className="wrapper">
        <div className="top-half">
          <div className="service">
            <h2>Compatability Interview</h2>
            <p>
              Not quite sure which option is best for you? Or maybe you need some more details before you sign up?
              Take a quick moment to fill out a Compatability Interview, and we can schedule a quick consult so 
              I can better understand your needs, and find the best plan and option for you.
            </p>
            <button onClick={() => {
                history.push('/request_consult')
            }}>Learn More</button>
          </div>
          <div className="service">
            <h2>Corporate Wellness Seminar</h2>
            <p>
              Looking for some guidance and help for the workplace? Perhaps you want to help your employees learn more
              about simple eating plans to help improve their health in and out of the office? Or maybe you're working 
              with a group of athletes, or even in the classroom, and you want to help your team learn how to dial in their 
              nutrition? This option is great for any group setting!
            </p>
            <button onClick={() => {
                history.push('/corporate_wellness')
            }}>Learn More</button>
          </div>
        </div>
        <div className="bottom-half">
          <div className='service'>
            <h3>Metabolic Consultation</h3>
            <p>
              A quick, 30 minute meeting to find out more about you and your goals. Receive expert recommendations that you can use on your own.
            </p>
            <button onClick={() => {
                history.push('/metabolic_consultation')
            }}>Learn More</button>
          </div>
          <div className='service'>
            <h3>Lifestyle Counseling</h3>
            <p>
              Personal counseling for you and your lifestyle. This is a great package for those who want to seek not just education, but hands 
              on help.
            </p>
            <button onClick={() => {
                history.push('/lifestyle_counseling')
            }}>Learn More</button>
          </div>
          <div className='service'>
            <h3>Advanced Package</h3>
            <p>
              A combination of the Metabolic Consultation and Lifestyle Counseling. Perfect for those who want in depth nutritional counseling.
            </p>
            <button onClick={() => {
                history.push('/advanced_package')
            }}>Learn More</button>
          </div>
          <div className='service'>
            <h3>Premier Package</h3>
            <p>
              The most exstensive nutritional counseling I can offer. Recommended for those who need medical nutritional intervention.
            </p>
            <button onClick={() => {
                history.push('/premier_package')
            }}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
}
