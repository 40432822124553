import React, { useState } from "react";
import { sendEmail } from "../utils/sendEmail";

export default function Contact() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="contact">
      <div className="wrapper">
        <h3>
          Interested in learning more about Evidenced Nutrition? Want to
          collaborate on a project? Send a message to get in contact with Jake!
        </h3>
        <div className="contact-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(
                visitor.fName,
                visitor.lName,
                visitor.email,
                visitor.message
              );
            }}
          >
            <div className="halves">
              <div className="left-half">
                <input
                  type="text"
                  name="fName"
                  required
                  placeholder="First Name"
                  value={visitor.fName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  name="lName"
                  required
                  value={visitor.lName}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  required
                  value={visitor.email}
                  onChange={handleChange}
                />
              </div>
              <div className="right-half">
                <textarea
                  name="message"
                  cols="30"
                  rows="10"
                  placeholder="Message: Min 140 characters, Max 1000 characters"
                  required
                  minLength="140"
                  maxLength="1000"
                  value={visitor.message}
                  onChange={handleChange}
                />
              </div>
            </div>
            <button type="submit">Send</button>
          </form>
        </div>
      </div>
    </div>
  );
}
