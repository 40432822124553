import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
// import { sendEmail } from "../utils/sendEmail";
export default function PremierPackage() {
  const price = 100000;
  const customerUrl = `${process.env.REACT_APP_BASE_URL}/customers`;
  const [customer, setCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("price", price);
    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);
    localStorage.setItem("service", "Premier Package");

    setCustomer({
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      email: "",
      fName: "",
      lName: "",
    });

    setCustomerId("0");
  }, [customerId, visitor.email, visitor.fName, visitor.lName]);

  const handleClick = async (e) => {
    e.preventDefault();

    await axios
      .post(customerUrl, customer)
      .then((res) => {
        setCustomerId(res.data.id);
      })
      .catch((err) => {
        console.log("Error, could not make new customer", err);
      });

    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);

    history.push("/checkout");
  };

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="service">
      <div className="wrapper">
        <h1>Premier Package</h1>

        <div className="content">
          <div className="description">
            <p>
              This is an extensive package that includes all the benefits from
              the Lifestyle Counseling and Metabolic Consultation Packages, but
              with more tools than the Advanced Package. This includes targeted
              interventions to meet specific goals and deadlines, and using
              intensive and cutting edge technology that goes beyond just
              tracking your body weight.
              <br /> <br />
              This can include bio-marker testing, like urine and blood, to
              check performance and health markers, as well as Medical
              Nutritional Therapy for performance or disease-specific needs.
              This can also include additional sessions with experts of
              interest, including Medical Doctors, Strength and Conditioning
              Specialists, Psychologists, and other topic related specialists at
              the request of the client, and availability of other specialists,
              at no additional cost.
              <br />
              <br />
              This can include up to daily contact as needed to help you reach
              your goals, as well as the Weekly and Monthly sessions included in
              the Advanced Package.
            </p>
            <br />
            <br />
            <p>Price: $1000.00</p>
            <p>Duration: 6 Month Minimum Contract</p>
          </div>
          <div className="form">
            <h3>Sign Up Now</h3>
            <form action="">
              <input
                name="fName"
                onChange={handleChange}
                value={visitor.fName}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lName"
                onChange={handleChange}
                value={visitor.lName}
                type="text"
                placeholder="Last Name"
              />
              <input
                name="email"
                onChange={handleChange}
                value={visitor.email}
                type="email"
                placeholder="Email"
              />
              <button onClick={handleClick}>Sign Up</button>
            </form>
          </div>
        </div>
        {/* <div className="email-form">
          <h2>Questions? Send them here!</h2>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              required
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div> */}
      </div>
    </div>
  );
}
