import emailjs from '@emailjs/browser';
import { messageSuccess, messageFail } from '../alerts/messageAlert';

export const sendEmail = (fName, lName, email, message) => {
  emailjs
    .send(
      'default_service',
      'template_2gqluco',
      {
        to_name: 'Jake',
        fName,
        lName,
        email,
        message,
      },
      'user_uscJE2OKi8jiqjr9ITiY4'
    )
    .then((res) => {
        if(res.status !== 200) {
            messageFail()
        } else {
            messageSuccess()
        }
    })
    .catch((err) => {
      console.log('NEWS FAILED HERE', err);
    });
};
